<template>
<columns>
    <column>
        <h4 class="title is-4">
            {{ widget.title }}
        </h4>
        <div v-html="parsedIntro"></div>
    </column>
    <column class="is-4">
        <div id="equipment-status-health">
            <apexchart type="donut" :options="chartOptions" :series="series"></apexchart>
        </div>
    </column>
</columns>
</template>
<script>
import ReportUtils from '../utils'

export default {

    props: {
        rows: {
            type: Array,
            default: () => ([])
        },
        widget: {
            type: Object,
            default: () => ({})
        },
        date: String
    },

    computed: {
        chartOptions() {
            return {
                chart: {
                    type: 'donut',
                },
                colors: ['#E91E63', '#1de98a'],
                labels: ['Faulty', 'Operational'],
                legend: {
                    show: false,
                },
                stroke: {
                    colors: ['#212127'],
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 150,
                            },
                        },
                    },
                ],
                tooltip: {
                    theme: 'dark',
                }
            }
        },
        series() {
            return [
                this.rows.filter(row => row.status === 'faulty').length, 
                this.rows.filter(row => row.status === 'operational').length
            ];
        },

        parsedIntro() {
            return ReportUtils['parse_keys'](this.widget.intro, this.rows, this.date)
        }
    }

}
</script>