import moment from 'moment'

export const total = (rows) => rows.length

export const total_faulty = (rows) => rows.filter(row => row.status === 'faulty').length

export const total_operational = (rows) => rows.filter(row => row.status === 'operational').length

export const month = (date) => {
    return date ? moment(date).format('MMMM') : moment().format('MMMM')
}

const keys = [
    { key: '{total}', handler: (rows) => total(rows) },
    { key: '{total_faulty}', handler: (rows) => total_faulty(rows) },
    { key: '{total_operational}', handler: (rows) => total_operational(rows) },
    { key: '{month}', handler: (rows, date) => month(date) },
]

const ranges = [
    { value: 'day', label: 'Daily' },
    { value: 'week', label: 'Weekly' },
    { value: 'month', label: 'Monthly' },
    { value: '6_months', label: '6 Months' },
    { value: 'annual', label: 'Annual' },
]

export const parse_keys = (text, rows, date) => {
    let updated_text = text || ''
    keys.forEach(key => {
        updated_text = updated_text.replace(key.key, key.handler(rows, date))
    })
    return updated_text
}

export const build_report_filtering_date = (range, date) => {

    let start_date = date ? moment(date) : moment()

    switch(range) {
        case 'day': return start_date.format('Y-MM-DD')
        case 'month': return start_date.startOf('month').format('Y-MM-DD')
        case 'week': return start_date.startOf('week').format('Y-MM-DD')
    }
}

export default {
    total,
    total_faulty,
    parse_keys,
    build_report_filtering_date,
    ranges
}